<template>
  <main class="com-main">
    <slot></slot>
  </main>
</template>
<script lang="ts" setup></script>
<style lang="scss" scoped>
.com-main {
  width: 100%;
  flex: 1;
}
</style>
